import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/CPyOgb_KBtM">
    <SEO title="A Hymn of Praise - Greatest Hits" />
  </Layout>
)

export default SermonPost
